@media (max-width: $smartphone) {
    .widget-nfts {
        --font-size: var(--font-size-xxlarge);
        color: var(--2112-yellow);
        margin: var(--padding-s) 0 0;

        .native-console-text {
            display: none;
        }
    }

    .widget-nfts__faction {
        height: auto;
        padding: 0 calc(var(--grid) * 2) 0;

        .native-console-text {
            display: none;
        }
    }

    .widget-nfts__title-holder {
        display: none;
    }

    .widget-nfts__images-holder {
        display: none;
    }

    .widget-nfts__faction-mobile {
        display: block;
        padding: 0 calc(var(--grid) * 2) 0;
    }

    .widget-nfts__faction-mobile__faction {
        > .title {
            @include font-sans();
            color: var(--terciary);
            font-size: var(--font-size-large);
            text-transform: uppercase;
            margin-bottom: -.08em;
        }

        > .text {
            @include font-mono();
            text-align: left;
            color: var(--primary);
            font-size: var(--font-size-interface);
        }

        > .slider {
            width: 100vw;
            margin-left: calc(-2 * var(--grid));
            padding: calc(2 * var(--grid));
            display: flex;
            overflow-x: auto;
            scroll-snap-type: x mandatory;
            gap: calc(var(--grid) * 1);

            > .slide {
                flex: 1 0 80%;
                width: 80%;
                border: 2px solid var(--primary);
            }
        }
    }
}
.header-article {
    --font-size-title:  var(--font-size-xxlarge);
    --font-size-summary:  var(--font-size-title-article);
    --font-size-subtitle: var(--font-size-base);
    --padding-top: var(--padding-m);
    --padding-bottom: var(--padding-m);
    --padding-h: var(--padding-m);
    --padding-inside: 0;
    --columns: 4 / 10;
    
    @media (max-width: $smartphone) {
        --padding-top: var(--padding-xl);
        --font-size-title:  var(--font-size-xxlarge);
        --font-size-summary:  var(--font-size-large);
        --font-size-subtitle: var(--font-size-base);
        --padding-h: calc(var(--grid) * 2);
        --padding-inside: 0;
        --columns: 1 / 13;
    }

    padding: var(--padding-top) var(--padding-h) var(--padding-bottom);
    font-size: var(--font-size-base);
       

    > .title {
        position: relative;
        @include font-sans-italic();
        font-size: var(--font-size-title);
        width: 100%;
        overflow: hidden;
        color: var(--2112-blue);
        margin: 0 0 -.15em;
        text-transform: uppercase;
        
        > span {
            position: relative;
            display: block;
            white-space: nowrap;
        }

        @media (max-width: $smartphone) {
            display: none;
        }
    }
}

.header-article__categories {
    display: none;
}

.header-article__content {
    display: grid;
    grid-template-columns: repeat(12, 1fr);

    padding: 0 var(--padding-inside);

    > * {
        grid-column: var(--columns);
    }

    > .subtitle {
        position: relative;
        @include font-mono();
        color: var(--2112-blue);
        font-size: var(--font-size-subtitle);
        margin: 0;
        padding-bottom: .5em;
        white-space: pre-line;

        &:after {
            content:'';
            position: absolute;
            left:0;
            bottom: 0;
            width: 100vw;
            border-top: 1px solid currentColor;
        }
    }

    > .summary {
        @include font-sans();
        color: var(--primary);
        font-size: var(--font-size-summary);
        text-transform: uppercase;
        line-height: .8;
        margin: .2em 0 0;
        white-space: pre-line;
    }

    @include font-mono();
}
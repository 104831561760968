.native-main-text {
    display: none;
    color: var(--primary);
    padding: 0;
    margin: var(--padding-s) 0;
    font-size: var(--font-size-interface);
    

    > div {
        display: inline;
    }

    > .title {
        @include font-mono();
        text-align: left;

        display: block;
        margin: 1em 0;
        background-color: var(--primary);
        color: var(--black);
        font-size: var(--font-size-interface);

        &::before {
            content: "//";
        }
    }

    > .text {
        @include font-mono();
        text-align: left;
        font-size: var(--font-size-interface);
    }
}

.native-console-text {
    display: none;
    @include font-mono();
    padding: var(--padding-xs);
    border: 1px solid var(--primary);
    margin: var(--padding-s) 0;
    font-size: var(--font-size-interface);
    text-align: left;

    &:empty {
        display: none;
    }

    > .title {
        @include font-mono();
        text-align: left;
        color: var(--primary);
        background-color: var(--terciary);
        font-size: var(--font-size-interface);
                
        &::before {
            content: "//";
        }

        &:empty {
            display: none;
        }
    }

    > .text {
        @include font-mono();
        text-align: left;
        color: var(--primary);
        font-size: var(--font-size-interface);

        &:empty {
            display: none;
        }
    }

    .__art, .art {
        &:empty {
            display: none;
        }
    }

    padding: var(--padding-xs);
}

@media (max-width: $smartphone) {
    .native-main-text,
    .native-console-text {
        display: block;

        .__art, .art {
            display: none;
        }
    }
}
.widget-team {
    --offset: calc(38vw - var(--grid) * 2);

    padding: 20vh 0;
    color: var(--2112-yellow);
    user-select: none;
}

.widget-team__title {
    position: relative;
    @include font-sans-italic();
    font-size: var(--font-size-xxlarge);
    color: var(--primary);
    text-align: center;
    height: .8em;
    margin: 0;

    .text {
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        display: block;
        white-space: nowrap;
    }
}

.widget-team__slider {
    position: relative;
    width: 100vw;
    height: 33vw;
    user-select: none;

    > .holder {
        position: absolute;
        top:0;
        left:0;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: nowrap;
        height: 100%;
    }
}

.widget-team__slider__item.void {
    width: var(--offset);
    margin-right: var(--grid);
}

.widget-team__slider__item:not(.void) {
    width: 25vw;
    perspective: 100px;
    background: transparent;
    border: 0;
    margin: 0;
    padding: 0;
    margin-right:var(--grid);

    &.--active {
        pointer-events: none;

        .caption {
            background: var(--primary);
            color: var(--secondary);
        }
    }

    > div {
        border: 1px solid var(--primary);
        transform-origin: center center;
        transform-style: preserve-3d;
    }

    .caption {
        display: flex;
        justify-content: space-between;

        border-top: 1px solid var(--primary);
        @include font-mono(1);
        font-size: var(--font-size-interface);
        color: var(--primary);
        background-color: var(--black);
        text-transform: uppercase;
        padding: var(--grid);

        .faction, .link, .bio, .console, .role {
            display: none;
        }
    }

    a {
        @include basic-a();
        color: var(--terciary);
    }

    img {
        -webkit-user-drag: none;
        -khtml-user-drag: none;
        -moz-user-drag: none;
        -o-user-drag: none;
        user-drag: none;
    }
}

.widget-team__scrollbar-holder {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: calc(var(--grid-small) * 3);
    

    .label {
        grid-column: 4 / 5;
        grid-row: 1 / 2;
        padding-right: var(--grid);
        @include font-mono(1);
        font-size: var(--font-size-interface-small);
        color: var(--primary);
        text-align: right;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }

    > .scrollbar {
        position: relative;
        grid-column: 5 / 9;
        grid-row: 1 / 2;
        cursor: pointer;

        &::after {
            content: '';
            position: absolute;
            top:0;
            left:0;
            width: 100%;
            height: 100%;
            border: 1px solid var(--primary);
        }

        > div {
            position: absolute;
        }

        .track {
            top:0;
            left:0;
            width: 100%;
            height: 100%;
        }

        .thumb {
            width: calc(100% - (var(--grid-small) * 2));
            height: var(--grid-small);
            top:var(--grid-small);
            left:var(--grid-small);
            background: var(--primary);
            transform-origin: 0 0;
        }
    }
}
@keyframes promt {
  0% { opacity: 0; }
  50% { opacity: 1; }
  100% { opacity: 1; }
}

.body-console {
    @include font-mono();
    word-wrap: break-word;
    white-space: pre-wrap;

    pointer-events: all;

    > div {
      white-space: nowrap;
    }

    span {
      display: block;
      white-space: pre;

      &.--input {
        text-transform: uppercase;

        &::after {
          content: '▋';
          animation-name: promt;
          animation-duration: .6s;
          animation-iteration-count: infinite;
        }
        
        &::before {
          content: '\\\\';
        }
        
        &:not(:first-child) {
          margin-top: 1em;
        }
      }
    }
  
    h2,h3 {
      display: inline-block;
      font-size: var(--font-size);
      margin: 1em 0;

      &:first-child {
        margin-top: 0;
      }
    }
  
    h2 {
      display: inline-block;
      background-color: var(--primary);
      color: var(--secondary);
      font-weight: 700;
      font-size: var(--font-size);
      margin: 1em 0;

      &::after {
        display: none;
      }
    }
  
    h3 {
      border: 1px solid currentColor;
      font-weight: normal;
      padding: 0 1em;
    }
  
    blockquote {
      padding: 0 1em;
      color: var(--2112-blue);
      word-wrap: normal;
      margin: 0;
      padding: 0;
    }
    p {
      margin: 1em 0;  
    }
    p:first-child {
      margin-top: 0; 
    }
    p:last-child {
      margin-bottom: 0;   
    }
    a {
      display: block;
      @include basic-a();
      pointer-events: all;
      text-decoration: none;
      color: var(--primary);
      
      &[target="_blank"]::after {
        content: ' ↗';
      }

      &:hover {
        text-decoration: underline;
      }
      margin-top: 1em;
    }

    h2 + p,
    h3 + p {
      margin-top: 0; 
    }
    
    &.__keyboard {
      *:last-child {
        &::after {
          content: '▋';
        }
      }
    }
  }
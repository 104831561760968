.block-text {
  --font-size: var(--font-size-base);
  --padding-top: var(--padding-m);
  --padding-bottom: var(--padding-m);
  --padding-h: var(--padding-m);
  --columns: 4 / 10;

  @media (max-width: $smartphone) {
    --columns: 1 / 13;
    --padding-h: calc(var(--grid) * 2);
  }


  padding: 0 var(--padding-h);
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  color: var(--primary);
}

.block-text__content {
  grid-column: var(--columns);
}

@media (max-width: $smartphone) {
  .block-text {
      
  }
}

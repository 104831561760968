.widget-roadmap {
    --padding-h: var(--padding-m);
    --font-size-title: var(--font-size-m);

    margin: 10vw 0;
    color: var(--2112-yellow);
    
    .title-big {
        margin-bottom: .2em;
    }
}

.widget-roadmap__phase {
    padding: 0 var(--padding-h);
    display: grid;
    gap: calc(var(--grid) * 3);
    grid-template-columns: repeat(12, 1fr);
}

.widget-roadmap__phase__title {
    --offset: calc(2 * var(--header-height) + var(--grid));

    grid-column: 1 / 4;
    color: var(--terciary);
    text-align: center;
    align-self: flex-start;
    justify-self: flex-end;
    padding-top: var(--offset);
    margin-top: calc(-1 * var(--offset));
    
    .number {
        display: block;
        @include font-mono(1);
        font-size: 20vw;
        letter-spacing: -.1em;
        margin-left: -.1em;
        margin-bottom: -.1em;
        margin-top: -.18em;
    }

    .text {
        display: block;
        @include font-sans();
        font-size: 8vw;
    }
}

.widget-roadmap__phase__holder-items {
    grid-column: 4 / 10;
}

.widget-roadmap__phase__item {
    padding-bottom: var(--padding-s);
    margin-bottom: var(--padding-s);
    border-bottom: 2px solid var(--terciary);

    > .title {
        @include font-sans(1);
        text-transform: uppercase;
        font-size: var(--font-size-xlarge);
        line-height: .8;
    }

    > .date {
        @include font-mono();
        color: var(--terciary);
        font-size: var(--font-size-mid);
        margin-bottom: .7em
    }

    > .text {
        @include font-mono();
        font-size: var(--font-size-base);
    }
}

.widget-pre-faq {
    margin: 10vw 0;
}

.widget-faq {
    --padding-h: var(--padding-m);
    --font-size-title: var(--font-size-m);
    padding-bottom: 20vh;
    color: var(--2112-yellow);
    
    .title-big {
        margin-bottom: .2em;
    }
}

.widget-faq__title {
    @include font-sans();
    font-size: 100vh;
    height: 300vh;
    color: var(--primary);

    .holder {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100vh;
        overflow: hidden;
    }

    .text {
        position: absolute;
        padding: .1em 100vw 0;
        top: 0;
        left: 0;
        display: block;
        white-space: nowrap;
    }
}

.widget-faq__ul {
    list-style: none;
    display: grid;
    gap: var(--padding-h);
    grid-template-columns: repeat(12, 1fr);
    padding: 0 var(--padding-h);
}

.widget-faq__ul__item {
    grid-column: 4 / 10;

    padding-bottom: var(--padding-s);
    margin-bottom: var(--padding-s);
    border-bottom: 2px solid var(--terciary);

    > .title {
        &:before {
            content: '//';
            color: var(--primary);
        }
        @include font-mono();
        color: var(--terciary);
        font-size: var(--font-size-base);
        margin-bottom: .7em;
    }

    > .text {
        @include font-mono();
        color: var(--primary);
        font-size: var(--font-size-base);
    }
}

@media (max-width: $smartphone) {
    .widget-faq {
        padding: 0 calc(var(--grid) * 2) var(--padding-s);

        .native-console-text {
            display: none;
        }
    }

    .widget-faq__ul {
        display: block;
        padding: 0;
    }
}

.widget-nfts {
    --font-size: var(--font-size-xxlarge);
    
    color: var(--2112-yellow);
    margin: 10vw 0 0;
}

.widget-nfts__faction {
    --size-visor: 35vw;

    @media  (min-aspect-ratio: 2000/1000) {
        --size-visor: 60vh;
    }

    height: 1200vh;
    
    .title {
        @include font-sans();
        @include z-index(2);
        font-size: var(--font-size-xxlarge);
        color: var(--terciary);
        text-align: center;
        text-transform: uppercase;
        white-space: nowrap;

        > div {
            display: block;
        }
    }

    .images {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        grid-gap: var(--grid);
        perspective: 1000px;
        

        > div {
            position: relative;
            --column: 0;
            --row: 0;
            align-items: flex-start;
            justify-content: flex-start;
            border: 1px solid var(--primary);
            transform-style: preserve-3d;

            &:first-child {
                @include z-index(3);
                grid-column: 5 / 9;
                grid-row: 1 / 2;
            }

            &:not(:first-child) {
                @include z-index(-1);
                grid-column: span 2;
                align-self: flex-start;
            }
        }
    }
}

.widget-nfts__title-holder {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    
    .widget-nfts__titles {
        padding: 10vw 0 10vw;
    }

    .widget-nfts__titles span {
        display: block;
        @include font-sans();
        font-size: var(--font-size-xxlarge);
        
        text-transform: uppercase;
        white-space: nowrap;
        margin-bottom: -.28em;

        -webkit-text-stroke: 1px var(--terciary);
        color: transparent;

        &.--active {
            color: var(--terciary);
        }
    }
}

.widget-nfts__images-holder {
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;

    display: flex;
    justify-content: center;
    align-items: start;
}

.widget-nfts__visor-images {
    position: relative;
    width: 35vw;
    height: 35vw;

    @media  (min-aspect-ratio: 2300/1000) {
        width: 50vh;
        height: 50vh;
    }

    border: 1px solid var(--primary);
    background: var(--black);

    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform-origin: center center;
        object-fit: cover;
        object-position: center;
        visibility: hidden;
    }

    .caption {
        position: absolute;
        width: calc(100% + 2px);
        bottom: 0;
        left: -1px;
        transform: translateY(100%);
        display: flex;
        justify-content: space-between;

        border: 1px solid var(--primary);
        @include font-mono(1);
        font-size: var(--font-size-interface);
        color: var(--primary);
        background-color: var(--black);
        text-transform: uppercase;
        padding: var(--grid);
    }
}

.widget-nfts__faction-mobile {
    display: none;
}
#Header {
    --width: 100%;
    --height: var(--header-height);
    --color: var(--primary);
    
    --padding-v: 0;
    --padding-h: var(--font-size-interface);
    --font-size: var(--font-size-interface);
}

#Header {
    @include z-index($z-index-interface);
    
    position: absolute;
    width: var(--width);
    
    right: 0;
    top: 0;
  
    pointer-events: all;
    display: flex;
    flex-wrap: wrap;

    @include font-mono(1);
    color: var(--color);

    opacity: 0;
}

.header__nav-legal,
.header__nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 100%;
  width: 100%;
  height: var(--height);
  padding: var(--padding-v) var(--padding-h);

  > a {
    @include basic-a();
    font-size: var(--font-size);
    color: inherit;

    &[aria-current="page"] {
      pointer-events: none;
    }

    &.--mobile {
      display: none;
    }

    &:hover {
      color: var(--white);
    }
  }
}

.header__nav .header-link--home { display: inline-block; }
.header__nav .header-link--article { display: none; }

.header__nav__rrss {
  flex: 1 0 100%;
  width: 100%;

  position: absolute;
  left:0;
  top:var(--height);
  
  display: none;
  gap: var(--grid);
  justify-content: space-between;
  align-items: center;
  flex: 1 0 100%;
  width: 100%;
  height: var(--header-height);

  > a {
    flex: 1 0 auto;
    padding: .7em 0;
    text-align: center;
    display: block;
    @include basic-a();
    font-size: var(--font-size);
    color: var(--black);
    background-color: var(--primary);
    border-radius: calc(var(--grid)*.3);
  }
}

@media (max-width: $smartphone) {
  #Header {
    
  }

  #Header::before,
  #Header::after {
    content: '→';
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: calc(-1 * var(--grid));
    top: 5%;
    height: 95%;
    width: calc(var(--grid) * 3);
    background-color: rgba(0, 0,0,.8);
  }

  #Header::before {
    top: var(--height);
    height: 100%;
    @include z-index(2);
  }

  .header__nav__rrss {
    display: flex;
    flex-wrap: nowrap;
    overflow-y: hidden;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
        display: none; // Safari and Chrome
    }

    > a {
      white-space: nowrap;
      padding-left: 1em;
      padding-right: 1em;

      &:last-of-type {
        padding-right: 2em;
      }
    }
  }

  .header__nav {
    flex-wrap: nowrap;
    overflow-y: hidden;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;

    &::-webkit-scrollbar {
        display: none; // Safari and Chrome
    }

    > a {
      white-space: nowrap;
     // display: none;
      padding: 1em;

      &.--mobile {
        display: inline-block;
      }
    }
  }

  .header__nav-legal,
  .header__nav {
      height: auto;
  }
}

.header__nav-legal {
  display: none;
}

.page-legal {
  .header__nav-legal {
    display: flex;
  }
  .header__nav {
    display: none;
  }
}

.page-article {
  .header__nav .header-link--article { display: inline-block; }
  .header__nav .header-link--home { display: none; }
}
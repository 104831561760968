@media (max-width: $smartphone) {
    .block-corps {
        padding: 0 calc(var(--grid) * 2) 0;

        .native-console-text {
            display: none;
        }

        > .marquees-holder {
            position: relative;
            width: 100vw;
            left: calc(-2 * var(--grid));
            bottom: 0;
            transform: translateY(0);

            > div:not(:first-child) {
                display: none;
            }
        }
    }

    .block-corps--mobile {
        display: block
    }
    .block-corps--mobile__corp {
        > .title {
            @include font-sans();
            font-size: 60px;
            line-height: .85;
            text-transform: uppercase;
            color: var(--terciary);
        }

        > .subtitle {
            @include font-mono();
            text-align: left;
            background-color: var(--terciary);
            color: var(--primary);
            font-size: var(--font-size-interface);
            margin: 1em 0
        }

        > .text {
            @include font-mono();
            text-align: left;
            color: var(--primary);
            font-size: var(--font-size-interface);
            margin: 1em 0
        }

        > .image {
            width: 100vw;
            margin-left: calc(-2 * var(--grid));
            margin-bottom: var(--padding-s);
        }
    }
 }
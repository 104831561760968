.next-article {
    --font-size-title:  var(--font-size-title-article);
    --font-size-label: var(--font-size-base);
    --padding-top: var(--padding-m);
    --padding-bottom: var(--padding-m);
    --padding-h: var(--padding-m);
    --columns: 4 / 10;
    
    @media (max-width: $smartphone) {
        --font-size-title: var(--font-size-large);
        --columns: 1 / 13;
        --padding-h: calc(var(--grid) * 2);
    }

    display: grid;
    grid-template-columns: repeat(12, 1fr);
    padding: var(--padding-top) var(--padding-h) var(--padding-bottom);
    font-size: var(--font-size-base);
    
    > * {
        grid-column: var(--columns);
    }

    a {
        @include basic-a();
        
        &:hover {
            .title {
                color: var(--primary);
            }
        }

        .label {
            @include font-mono(); 
            font-size: var(--font-size-label); 
            color: var(--primary);
            text-align: right;
    
            &::after {
                content: ' →';
            }
        }
    
        .title {
            position: relative;
            @include font-sans();
            line-height: .8;
            font-size: var(--font-size-title);
            width: 100%;
            color: var(--2112-blue);
            margin: .2em 0 -.15em;
            text-transform: uppercase;
            text-align: right;
        }
    }
}
.widget-collection {
    --font-size:  var(--font-size-interface);
    --padding-top: calc(var(--grid) * 10);
    --padding-bottom: var(--padding-m);
    --padding-h: var(--padding-m);
    --columns: 4 / 10;

    @media (max-width: $smartphone) {
        --padding-top: var(--padding-xl);
        --padding-h: calc(var(--grid) * 2);
        --columns: 1 / 13;
    }
}

.widget-collection {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    min-height: 100vh;
    padding: var(--padding-top) var(--padding-h) var(--padding-bottom);

    > * {
        grid-column: var(--columns);
    }

    @include font-mono();
    font-size: var(--font-size);
}

.widget-collection > ul {
    list-style: none;

    > li {
        margin-bottom: 1em;
    }

    a {
        display: block;
        @include basic-a();
       
        > .date {
            color: var(--2112-blue);
        }

        > .title {
            color: var(--primary);
            text-transform: uppercase;
            
            &::before {
                content: "\\\\";
                color: var(--2112-blue);
            }
        }

        > .category {
            color: var(--primary);
            font-size: var(--font-size-interface-small);

            &::after {
                content: "]";
            }
            &::before {
                content: "[";
            }
        }

        &:hover {
            background-color: var(--primary);

            > .category,
            > .date,
            > .title,
            > .title::before {
                color: var(--black);    
            }
        }
    }
}
.block-image {
  --columns: 4 / 10;
  --padding-h: 0;

  @media (max-width: $smartphone) {
      --columns: 1 / 13;
      --padding-h: calc(var(--grid) * 2);
  }

  display: grid;
  grid-template-columns: repeat(12, 1fr);
  color: var(--primary);
  margin: var(--padding-m) 0;
  padding: 0 var(--padding-h);
}

.block-image__content {
  grid-column: var(--columns);
  border: 1px solid var(--primary);

  .caption {
    @include font-mono();
    border-top: 1px solid var(--primary);        
    color: var(--2112-blue);
    padding: var(--grid);
  }
}

@media (max-width: $smartphone) {
  .block-image {
      
  }
}

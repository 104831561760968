#Cookies {
  --color: var(--primary);
  --bg: var(--secondary);
  --padding-v: var(--grid);
  --padding-h: var(--grid);
  --font-size-big: var(--font-size-interface-small);
  --font-size: var(--font-size-interface-small);

  &.__success {
    --bg: var(--color-sucess);
    --color: var(--black);
  }

  &.__error {
    --bg: var(--color-error);
    --color: var(--white);
  }

  &[aria-expanded='false'] {
    visibility: hidden;
    pointer-events: none;
  }
}


#Cookies {
  position: fixed;
  left: var(--grid);
  bottom: var(--grid);
  width: 40%;

  @include z-index($z-index-windows);

  display: flex;
  flex-wrap: wrap;
  padding: var(--padding-v) var(--padding-h);
  justify-content: space-between;
  align-items: center;

  background-color: rgba(0,0,0,.8);
  color: var(--color);
  border: 1px solid currentColor;
  border-radius: calc(var(--grid) * .5);

  .content {
    flex: 1 0 50%;
    display: block;
    position: relative;
  }

  .controls {
    flex: 1 0 160px;
    min-width: 160px;
    padding-left: 10px;
    text-align: center;

    display: flex;
    justify-content: flex-end;
  }
}

//TEXTO
#Cookies p {
  @include font-mono();
  font-size: var(--font-size);
  margin: 0;
  padding-bottom: 0;

  b, strong {
    @include font-mono();
  }

  a {
    @include basic-a();
    text-decoration: underline;
    color: inherit;
  }
}


///BOTON
#Cookies button {
  @include basic-a();
  @include font-mono();
  line-height: 1;
  font-size: var(--font-size-big);
  letter-spacing: var(--letter-spacing);
  border: 1px solid var(--color);
  background: transparent;

  &:hover {
    background-color: var(--color);
    color: var(--bg);
  }
}

@media (max-width: $smartphone) {
  #Cookies {
    padding: var(--grid);
    width: calc(100% - var(--grid) - var(--grid));

    .content {
      width: 100%;
      margin-bottom: var(--padding-xs);
    }

    .controls {
      width: 100%;
      min-width: 100%;
      padding: 0;
      display: flex;
    }

    p {
      font-size: var(--font-size-base);
      margin-bottom: 1em;
    }

    .btn-ok {
      width: 100%;
      &:first-child {
        margin-left: 0;
      }
      
    }

    /*.btn-nok {
      margin: 0;
      width: 35%;
      padding: 15px 30px;
    }*/
  }
}

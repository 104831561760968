.block-join-us {
  --font-size-link: #{(140px / 16px) * 1rem};
  margin-top: 10vh;
  padding: 0 0 20vh 0
}

.block-join-us__link {
  @include basic-a();
  @include font-mono(1);
  display: block;
  text-align: center;
  color: var(--terciary);
  font-size: var(--font-size-link);
  text-decoration: none;
   &:hover {
    color: var(--primary);
   }
}

@media (max-width: $smartphone) {
  .block-join-us {
    --font-size-link: #{(24px / 16px) * 1rem};
    margin-top: var(--padding-s);
    padding: 0 calc(var(--grid) * 2) 20vh;
    
    .marquee-scroll {
      display: none;
    }

    .native-console-text {
      display: none;
    }
  }
}

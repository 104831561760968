#Footer {
    --width: 100%;
    --height: var(--header-height);
    --color: var(--primary);
    
    --padding-h: var(--grid);
    --font-size: var(--font-size-interface-small);
}

#Footer {
    @include z-index($z-index-interface);

    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: var(--footer-height);

    display: flex;
    justify-content: flex-start;
    align-items: center;

    @include font-mono();
    color: var(--color);

    opacity: 0;
}

@media (max-width: $smartphone) {
  #Footer {
    display: none;
  }
}


.footer__nav {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1 0 100%;
    padding: 0 var(--padding-h);
    gap: var(--grid);
    font-size: var(--font-size);
    
    a {
      @include basic-a();
      color: inherit;
  
      &[aria-current="page"] {
        pointer-events: none;
      }
    }
  }

@media (max-width: $smartphone) {
  #Footer {
      --font-size: rem(12px);
  }
}

$z-index-bg: 0;

$z-index-3D: 1;
$z-index-wrap: 2;
$z-index-cookies: 3;
$z-index-goto-main: 4;
$z-index-progress: 5;
$z-index-categories: 5;
$z-index-sidemenu: 6;
$z-index-header: 10;
$z-index-footer: 10;
$z-index-scrollbar: 11;

$z-index-cursor: 10;
$z-index-interface-corps: 10;
$z-index-interface-bg: 11;
$z-index-interface: 12;
$z-index-interface-text: 13;
$z-index-windows: 13;
$z-index-windows-buttons: 14;
$z-index-loader: 15;


$z-index-reserva: 6;

.block-corps {
    position: relative;

    > .marquees-holder {
        position: absolute;
        left:0;
        bottom: 0;
        transform: translateY(90%);
    }
}

.block-corps--mobile {
    display: none;
}

.block-slider {
    --margin-v: 0;
    --padding-v: 0;
    --padding-h: 0;

    @media (max-width: $smartphone) {
       display: none;
    }
}

.block-slider {
    height: var(--height);
    margin: var(--margin-v) 0;
    overflow: hidden;
    padding: var(--padding-v) var(--padding-h);
    position: relative;

    > div {
        height: 100vh;
        position: relative;
    }

    &__wrapper {
        display: flex;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: auto;

        .slide {
            display: inline-flex;
            height: 100%;
            width: 100vw;

            &:first-child { background: green; }
            &:nth-child(2) { background: blue; }
            &:last-child { background: red; }
        }
    }
}

#Interface {
  --size-left-bar: calc(var(--grid) * 35);
  --size-right-bar: calc(var(--grid) * 36);
  --size-widgtes-bar: calc(var(--grid) * 25);
  --size-widgtes-bar: calc(var(--grid) * 25);
  --size-rrss-bar: calc(var(--grid) * 10);

  --height-scrollbar: calc(var(--grid) * 2);
  --height-logo: calc(var(--grid) * 4);
  --margin-logo: calc(var(--grid) * 4);
  --height-globe: calc(var(--grid) * 13);
  --height-graphs: calc(var(--grid) * 4);
  --color: var(--primary);
  --font-size: var(--font-size-interface-small);
}

.interface-bg {
    @include z-index($z-index-interface-bg);
    position: fixed;
    top:0;
    left:0;
    width: 100vw;
    height: 100vh;
    pointer-events: none;

    &:before,
    &:after {
      @include pseudo-element-absolute();
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: radial-gradient(circle, rgba(0, 0, 0, .1) 40%, rgba(0, 0, 0, .5) 70%, black 100%);
    }

    &:before {
      @include z-index(2);
      background-image: url($images-path + "scanline.png");
      background-size: 1px 4px;
      background-repeat: repeat;
      opacity: .1;
    }
}

#Interface {
  @include z-index($z-index-interface-text);

  position: fixed;
  top:var(--grid);
  left:var(--grid);
  width: calc(100% - var(--grid) * 2);
  height: calc(100% - var(--grid) * 2);
  pointer-events: none;

  .frame {
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
  }

  .scrollbar {
    position: absolute;
    top: var(--header-height);
    left: var(--grid);
    width: var(--size-left-bar);
    height: var(--height-scrollbar);
    pointer-events: all;
    cursor: pointer;

    @media (max-width: $smartphone) {
      top: calc(var(--header-height) * 2);
    }

    > div {
      position: absolute;
      width: 100%;
      height: 200%;
      top:0;
      left:0;
    }
  }
}

.hud__left {
  position: absolute;
  @include z-index($z-index-interface-text);
  left: var(--grid);
  bottom: 0;
  height: calc(100% - var(--header-height) - var(--height-scrollbar));
  width: var(--size-left-bar);

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  .logo {
    width: var(--size-widgtes-bar);
    height: var(--height-logo);
    margin-top: var(--margin-logo);
  }

  .globe {
    width: var(--size-widgtes-bar);
    height: var(--height-globe);
  }

  .graphs {
    width: var(--size-widgtes-bar);
    height: var(--height-graphs);
    margin: var(--grid) 0;
    display: flex;

    .graphs__steps {
      flex: 1 0 var(--height-graphs);
      height: 100%;
    }

    .graphs__radar,
    .graphs__circles {
      height: 100%;
      width: var(--height-graphs);
    }

    .graphs__radar {
      margin: 0 var(--grid);
    }
  }
}

.hud__main-text {
  flex: 1 0 auto;
  @include font-mono(0);
  padding: calc(var(--grid) * 3) 0 calc(var(--grid) * 2);
  color: var(--color);
  font-size: var(--font-size);

  .text {
    word-wrap: break-word;
  }

  a,button {
    pointer-events: all;
  }

  & .--title {
    background-color: var(--primary);
    color: var(--secondary);
    font-weight: 600;
  }
}

.hud__right {
  position: absolute;
  @include z-index($z-index-interface-text);
  right: var(--grid);
  top: calc(var(--header-height) + var(--grid));
  width: auto;
  min-width: var(--size-right-bar);
  height: calc(100% - var(--header-height) - var(--grid));
  
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
}

.hud__aux-text {
  position: relative;
  width: 100%;
  height: auto;
  flex: 1 0 auto;

  overflow: hidden;
  color: var(--color);
  font-size: var(--font-size);
  /*clip-path: polygon(
    0 0, 
    calc(100% - var(--size-rrss-bar) - var(--grid)) 0%, 
    calc(100% - var(--size-rrss-bar) - var(--grid)) calc(var(--grid) * 24), 
    100% calc(var(--grid) * 24), 
    100% 100%, 
    0 100%);*/

  > .text {
    position: absolute;
    width: 100%;
    bottom: 0;
    //right: -.5em;
    padding: calc(var(--grid) * 2) 0;
    
    
    &[aria-expanded=false] {
      display: none;
    }
  }

  a,button {
    pointer-events: all;
  }
}

.hud__aux-controls {
  display: flex;
  gap: var(--grid);
  justify-content: flex-end;
  width: 100%;
  gap: var(--grid);
  padding: 0 0 var(--grid);
  pointer-events: all;
  opacity: 0;

  > button {
    @include basic-a();
    border: 0;
    background: transparent;
    @include font-mono();
    font-size: var(--font-size-interface-small);
    height: calc(var(--grid) * 2);
    padding: 0 var(--grid);
    color: var(--primary);

    &:hover {
      color: var(--secondary);
    }

    &.--advise {
      color: var(--secondary);
    }
  }
}

.hud__right__rrss {
  position: absolute;
  display: flex;
  width: 100%;
  justify-content: center;
  left: 0;
  bottom:  var(--grid);
  flex: 0 0 auto;
  list-style: none;
  pointer-events: none;
 
  @include z-index($z-index-interface-text);
  @include font-mono(1);
  
  li {
    &:not(:last-of-type) {
      margin: 0 var(--grid) 0 0;
    }
    width: var(--size-rrss-bar);
  }

  a {
    @include  basic-a();
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    font-size: var(--font-size-interface-small);
    height: calc(var(--grid) * 3);
    padding: .25em var(--grid);
    color: var(--secondary);
    text-align: right;
    text-transform: uppercase;
    pointer-events: all;
  }
}

.hud__open_wins {
  display: none;
}
html{
    width: 100%;
    height: 100%;
    background: var(--secondary);
}

body{
    width: 100%;
    min-height: 100%;

    main {
        width: 100vw;
        overflow: hidden;
    }

    &:not(.__scroll-manual){
        overflow: hidden;

        main {
          position: fixed;
          @include z-index($z-index-wrap);
          height: 100%;
          top:0;
          left:0;
        }
    }

    &.__scroll-manual {
        overflow-y: scroll;
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;
    }

}


.win-categories {
  position: fixed;
  top: calc(var(--grid) * 12);
  left: calc(var(--grid) * 2);
  @include z-index($z-index-interface);

  display: flex;
  flex-direction: column;

  @include font-mono();
  font-size: var(--font-size-interface-small);

  visibility: hidden;
  pointer-events: none;

  &[aria-expanded='true'] {
    visibility: visible;
    pointer-events: all;
  }

  ul {
      --level: 0;
      --padding: calc(var(--grid) * 2 * var(--level));

      list-style: none;
      padding-left: var(--padding);

      > li {
          margin: .2em 0;
      }

      > li > ul {
          --level: 1;
          position: relative;
          display: none;

          li:first-of-type::before {
              content: '';
              position: absolute;
              top:.2em;
              left:.5em;
              width: .5em;
              height: .5em;
              border-left: 1px solid var(--2112-blue);
              border-bottom: 1px solid var(--2112-blue);
          }
      }

      > li[aria-expanded="true"] > ul {
          display: block;
      }

      > li[aria-expanded="true"] > a > span::before { content: "-";}
  }

  a {
      @include basic-a();
      text-decoration: none;
      color: var(--primary);

      > span::before { content: "+";}

      &:hover {
        background-color: var(--primary);
        color: var(--black);
      }

      &[aria-current="page"] {
        //pointer-events: none;
        background-color: var(--primary);
        color: var(--black);
      }
  }
}

@media (max-width: $smartphone) {
  .win-categories {
    position: fixed;
    @include z-index($z-index-windows);
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($color: #000000, $alpha: .6);

    padding: calc(var(--grid) * 13) calc(var(--grid) * 2);
  }
}
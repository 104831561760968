.block-video {
  --columns: 4 / 10;
  --padding-h: 0;

  @media (max-width: $smartphone) {
      --columns: 1 / 13;
      --padding-h: calc(var(--grid) * 2);
  }

  display: grid;
  grid-template-columns: repeat(12, 1fr);
  margin: var(--padding-m) 0;
  padding: 0 var(--padding-h);
}

.block-video__content {
  grid-column: var(--columns);
  border: 1px solid var(--primary);

  @include aspect-ratio(16,9);

  > iframe {
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
  }
}

.legal {
    display: grid;
    gap: var(--grid);
    grid-template-columns: repeat(12, 1fr);
}

.block-legal {
    grid-column: 4 / 10;
    @include font-mono();
    font-size: var(--font-size-base);
    color: var(--primary);
    padding: 10vh 0;
  
    h1,h2,h3,h4,p,ol,ul {
      margin: 1em 0;
    }
  
    a {
      color: var(--primary);
    }
  }
  

@media (max-width: $smartphone) {
    .widget-team {
        --offset: calc(20vw - var(--grid) * 2);

        .native-console-text {
            display: none;
        }
    }
    .widget-team__title {
        @include font-sans-italic();
        font-size: var(--font-size-large);
    }

    .widget-team__slider {
        height: 100vw;

        &::after,
        &::before {
            position: absolute;
            top: 0;
            height: 100%;
            padding: calc(var(--grid) * 2);
            @include font-mono(1);
            font-size: 20px;
            color: var(--primary);
            display: flex;
            align-items: center;
            justify-content: center; 
            pointer-events: none;
        }

        &::after {
            right: 0;
            content: '→';
            @include z-index(3);
        }

        &::before {
            left: 0;
            content: '←';
            @include z-index(3);
        }
    }

    .widget-team__slider__item:not(.void) {
        width: 70vw;
        .caption {
            flex-wrap: wrap;
            .name, .faction {
                text-align: left;
                margin-bottom: .8em;
            }

            .role {
                text-align: right;
            }
            
            .name, .role {
                flex: 0 0 50%;
                width: 50%;
            }

            .faction, .link, .role {
                display: block;
            }

            .link {
                @include ellipsis();
            }
        }

        &.--active {
            pointer-events: all;
        }
    }

    .widget-team__scrollbar-holder {
        display: none;
    }
}
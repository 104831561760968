:root {
  --y-header: 0;

  --padding-xl: #{(120px / 16px) * 1rem};
  --padding-l: #{(90px / 16px) * 1rem};
  --padding-m: #{(60px / 16px) * 1rem};
  --padding-s: #{(30px / 16px) * 1rem};
  --padding-xs: #{(20px / 16px) * 1rem};
  --padding-xxs: #{(10px / 16px) * 1rem};

  --grid: #{(10px / 16px) * 1rem};
  --grid-small: #{(5px / 16px) * 1rem};
  
  --footer-height:calc(var(--grid) * 3);
  --header-height:calc(var(--grid) * 4);
}

@media (max-width: $smartphone) {
  :root {}
}

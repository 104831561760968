.widget-video-intro {
    width: 100vw;
    height: 100vh;

    video {
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
    }
}

.widget-video-intro__mint {
    @include z-index(2);
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: calc(var(--grid) * 10);
    

    a {
        --sizePolygon: 1vw;
        display: block;    
        @include basic-a();
        @include font-mono();
        background-color: var(--2112-yellow);
        color: var(--black);
        font-size: var(--font-size-h3);
        padding: .4em .8em;
        line-height: 1;
        text-transform: uppercase;

        clip-path: polygon(0 0, 100% 0, 100% 100%, var(--sizePolygon) 100%, 0 calc(100% - var(--sizePolygon)));

        &:hover {
            background-color: var(--white);
        }
    }
}

@media (max-width: $smartphone) {
    .widget-video-intro__mint {
        position: relative;
        height: auto;
        padding: calc(var(--grid) * 2) 0;
    }
    .widget-video-intro {
        width: 100vw;
        height: auto;
        padding: calc(var(--header-height) * 4) calc(var(--grid) * 2) 0;
        margin-bottom: var(--padding-s);
    
        video {
            position: relative;
            width: 100%;
            height: auto;
            object-fit: cover;
            object-position: center center;
            border: 1px solid (var(--primary));
        }

        .native-console-text {
            display: none;
        }
    }
}
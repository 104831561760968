.marquee-scroll {
    position: relative;
    width: 100vw;
    height: .8em;
    overflow: hidden;

    @include font-sans();
    font-size: var(--font-size-xlarge);
    color: var(--terciary);
    text-transform: uppercase;

    .content {
        position: absolute;
        top:0;
        left:0;
        display: flex;
        flex-wrap: nowrap;
        white-space: nowrap;
    }

    strong {
        @include font-sans();
        color: var(--primary);
    }

    i {
        @include font-sans-italic();
    }
}
.block-quote {
  --font-size: var(--font-size-h2);
  --columns: 4 / 10;
  --padding-h: 0;

  @media (max-width: $smartphone) {
      --font-size: var(--font-size-h3);
      --columns: 1 / 13;
      --padding-h: calc(var(--grid) * 2);
  }

  display: grid;
  grid-template-columns: repeat(12, 1fr);
  padding: 0 var(--padding-h);

  color: var(--2112-blue);
  @include font-sans();
  text-transform: uppercase;
  line-height: .8;
  margin: .4em 0;
  font-size: var(--font-size);
}

.block-quote__content  {
  grid-column: var(--columns);
}

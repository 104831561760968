.title-big {
    --font-size: var(--font-size-xxlarge);

    @media (max-width: $smartphone) {
        --font-size: var(--font-size-large);
    }
}

.title-big {
    --offset: -.28em;
    @include font-sans-italic();
    font-size: var(--font-size);
    color: var(--primary);
    margin-bottom: calc(-.5 * var(--offset));
    span {
        width: 100%;
        text-align: center;
        display: block;
        margin-bottom: var(--offset);
    }
}
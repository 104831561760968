@media (max-width: $smartphone) {
  #Interface {
    --size-left-bar: calc(100% - var(--grid) - var(--grid));
    --size-right-bar: calc(var(--grid) * 36);
    --size-widgtes-bar: calc(var(--grid) * 16);
    --size-rrss-bar: calc(var(--grid) * 10);
  
    --height-scrollbar: calc(var(--grid) * 2);
    --height-logo: calc(var(--grid) * 3);
    --margin-logo: calc(var(--grid) * 2);
    --height-globe: calc(var(--grid) * 6);
    --height-graphs: calc(var(--grid) * 2);
    --color: var(--primary);
    --font-size: var(--font-size-interface-small);
  }

  .hud__right {
    display: none;
  }

  .hud__main-text {
    //display: none;
  }

  .hud__aux-text {
    display: none;
  }


  .hud__right__rrss {  
    display: none;
    
    a {
      background-color: var(--primary);
    }
  }

  .hud__open_wins {
    display: block;
    position: fixed;
    @include z-index($z-index-windows-buttons);
    right: calc(var(--grid) * 2);
    bottom: calc(var(--grid) * 5);
    @include font-mono();
    font-size: var(--font-size-interface-small);

    > button {
      display: none;
      @include basic-a();
      background-color: var(--primary);
      color: var(--black);
    }
  }
}
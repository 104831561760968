.body-articles {
    @include font-mono();
      
    h2,h3 {
      display: inline-block;
      font-size: var(--font-size);
      margin: 1em 0;

      &:first-child {
        margin-top: 0;
      }
    }
  
    h2 {
      display: inline-block;
      background-color: var(--primary);
      color: var(--secondary);
      font-weight: 700;
      font-size: var(--font-size);
      margin: 1em 0;

      &::after {
        display: none;
      }
    }
  
    h3 {
      border: 1px solid currentColor;
      font-weight: normal;
      padding: 0 1em;
    }
  
    blockquote {
      padding: 0 1em;
      color: var(--2112-blue);
      word-wrap: normal;
      margin: 0;
      padding: 0;
    }
    p {
      margin: 2em 0;  
    }
    p:first-child {
      margin-top: 0; 
    }
    p:last-child {
      margin-bottom: 0;   
    }

    li {
      position: relative;
      padding-left: 1em;

      &:before {
        content: '#';
        position: absolute;
        top:0;
        left:0;
      }
    }
    
    a {
      display: block;
      @include basic-a();
      pointer-events: all;
      text-decoration: none;
      color: var(--primary);
      &:after {
        content: ' ↗';
      }
      &:hover {
        text-decoration: underline;
      }
      margin-top: 1em;
    }

    h2 + p,
    h3 + p {
      margin-top: 0; 
    }
    
    &.__keyboard {
      *:last-child {
        &::after {
          content: '▋';
        }
      }
    }
  }
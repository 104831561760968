@media (max-width: $smartphone) {
    .widget-roadmap {
        --font-size-title: var(--font-size-large);

        padding: 0 calc(var(--grid) * 2) 0;

        .native-main-text {
            display: none;
        }
    }

    .widget-roadmap__phase {
        padding: 0;
        display: block;

        .native-console-text {
            display: none;
        }
    }

    .widget-roadmap__phase__title {
        --offset: 0;

        color: var(--terciary);
        text-align: center;
        align-self: flex-start;
        justify-self: flex-end;
        padding-top: 0;
        margin: var(--padding-s) 0;

        .number {
            font-size: 40vw;
        }

        .text {
            font-size: 16vw;
        }
    }

    .widget-roadmap__phase__item {
        padding-bottom: 0;
        margin-bottom: var(--padding-s);
        border-bottom: 2px solid var(--terciary);

        > .title {
            @include font-sans(1);
            text-transform: uppercase;
            font-size: var(--font-size-h3);
            line-height: .8;
        }

        > .date {
            @include font-mono();
            color: var(--terciary);
            font-size: var(--font-size-mid);
            margin-bottom: .7em
        }

        > .text {
            @include font-mono();
            font-size: var(--font-size-interface);
            padding-bottom: var(--padding-s);
        }
    }
}

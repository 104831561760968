:root {
  --white: #FFFFFF;
  --black: #000;
  --grey: #999;

  --2112-yellow: #FEEE08;
  --2112-blue: #444DBC;

  --primary: var(--2112-yellow);
  --secondary: var(--black);
  --terciary: var(--2112-blue);

  --assertive: #ff00ff;
  --focus: #ff00ff;
  --color-error: #ff0000;
  --color-sucess: #4dc247;
}

.palette-primary {
    --primary: var(--2112-yellow);
    --secondary: var(--black);
}

.palette-secondary {
    --primary: var(--black);
    --secondary: var(--2112-yellow);
}
:root {
    --font-mono: 'Supply-Mono';
    --font-sans: 'Evangelion', sans-serif;
    --font-sans-italic: 'Evangelion-Italic', sans-serif;

    --font-size-title-article: #{(140px / 16px) * 1rem};

    --font-size-xxlarge: #{(360px / 16px) * 1rem};
    --font-size-xlarge: #{(90px / 16px) * 1rem};
    --font-size-mid: #{(20px / 16px) * 1rem};
    --font-size-base: #{(16px / 16px) * 1rem};


    --font-size-large: #{(80px / 16px) * 1rem};
  
    
    
    --font-size-h2: #{(80px / 16px) * 1rem};
    --font-size-h3: #{(40px / 16px) * 1rem};
    --font-size-small: #{(15px / 16px) * 1rem};
    --font-size-xsmall: #{(12px / 16px) * 1rem};

    --font-size-interface: #{(14px / 16px) * 1rem};
    --font-size-interface-small: #{(12px / 16px) * 1rem};

    --line-height-sans: 1;
    --line-height-mono: 1.2;
    --letter-spacing: -0.06em;

    font-size: 1.1111vw;

    @media (max-width: $smartphone) {
        font-size: 16px;
    }
}

@mixin font-mono($line-height:0) {
  font-family: var(--font-mono);
  font-weight: normal;
  @if $line-height > 0 { line-height: $line-height  }
  @else { line-height: var(--line-height-mono);  }
}

@mixin font-sans($line-height:0) {
    font-family: var(--font-sans);
    font-weight: normal;
    @if $line-height > 0 { line-height: $line-height  }
    @else { line-height: var(--line-height-sans);  }
}

@mixin font-sans-italic($line-height:0) {
  font-family: var(--font-sans-italic);
  font-weight: normal;
  @if $line-height > 0 { line-height: $line-height  }
  @else { line-height: var(--line-height-sans);  }
}

@mixin font-sans-bold($line-height:0) {
  font-family: var(--font-sans-bold);
  font-weight: 500;
    @if $line-height > 0 { line-height: $line-height  }
    @else { line-height: var(--line-height-sans);  }
}